.<template>
<div class="w-full ">
    <nav class="py-4 bg-blue   text-white " :style="{ backgroundImage: 'url(' + require('@/assets/bg-footer.jpg') + ')' }">
        <div class=" mx-auto max-w-screen-xl">
            <div class="flex justify-between items-center flex-wrap mx-3">
                <router-link to="/">
                    <div id="logo">
                        <img src="@/assets/logo.png" alt="">
                    </div>
                </router-link>

                <div class="block sm:hidden">
                    <button @click="open = !open" class="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white">
                        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>
                <div :class="open ? 'block': 'hidden'" class="w-full flex-grow sm:flex sm:items-center sm:w-auto mt-2">
                    <div class="text-sm sm:flex-grow">

                    </div>
                    <ul id="enlaces" class="flex flex-col lg:flex-row justify-between lg:items-center poppins   text-sm font-bold ">

                        <li @click="tipoOperacion('Venta')" class="lg:mr-4 hover:bg-red-700 p-4 cursor-pointer rounded">
                            Comprar
                        </li>

                        <li @click="tipoOperacion('Alquiler')" class="lg:mr-4 hover:bg-red-700 p-4 cursor-pointer rounded">
                            Alquilar
                        </li>
                        <router-link to="/vender">
                            <li class="lg:mr-4 hover:bg-red-700 p-4 cursor-pointer rounded">
                                Vendé tu propiedad
                            </li>
                        </router-link>
                        <!-- <router-link to="/tasaciones">
                            <li  class="lg:mr-4 hover:bg-red-700 p-4 cursor-pointer rounded">
                                Tasaciones
                            </li>
                        </router-link>
                          <router-link to="/admalquileres">
                        <li   class="lg:mr-4 hover:bg-red-700 p-4 cursor-pointer rounded">
                            Admin. de alquileres
                        </li>
                         </router-link> -->
                        <router-link to="/quienessomos">
                            <li class="lg:mr-4 hover:bg-red-700 p-4 cursor-pointer  rounded">
                                Quienes somos
                            </li>
                        </router-link>

                        <li @click="contacto('Consultas generales')" class="hover:bg-red-700 p-4 cursor-pointer rounded">
                            Contacto
                        </li>

                    </ul>
                </div>

                <!--   <ul id="enlaces" class="flex justify-between items-center poppins  font-semibold ">
                    <li class="mr-6 hover:bg-red-700 p-4 cursor-pointer rounded">
                        Comprar
                    </li>
                    <li class="mr-6 hover:bg-red-700 p-4 cursor-pointer rounded">
                        Vender
                    </li>
                    <li class="mr-6 hover:bg-red-700 p-4 cursor-pointer rounded">
                        Alquilar
                    </li>
                    <li class="mr-6 hover:bg-red-700 p-4 cursor-pointer  rounded">
                        Quienes somos
                    </li>
                    <li class="hover:bg-red-700 p-4 cursor-pointer rounded">
                        Contacto
                    </li>
                </ul> -->

            </div>
        </div>
    </nav>
</div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex'

import router from "../router"
export default {
    name: "Navbar",
    data() {
        return {
            open: false
        }
    },

    methods: {
        ...mapActions([""]),
        obtenerPropiedadesporOperacion(payload) {
            window.location.href = "http://localhost:8080/propiedades/" + payload;
        },
        contacto(payload) {
            router.push({
                name: 'contacto',
                params: {
                    id: payload
                }
            })
        },
        tipoOperacion(payload) {
            router.push({
                name: 'propiedades',
                params: {
                    id: payload
                }
            })
        }

    },

}
</script>
