<template>
<div class="home">

    <section class=" relative flex justify-center  items-center object-cover w-full    min-h-screen  bg-cover  bg-no-repeat" style="background-image: url(https://i.pinimg.com/originals/d6/25/e2/d625e2786965ea276c2ed4dadc41cc6e.jpg)">

        <Navbar class="absolute top-0 bg-blue-900"></Navbar>

        <div class="flex flex-col">
            <h1 class="text-white text-center text-3xl lg:text-4xl poppins font-bold">Trabajamos por y para tu tranquilidad.</h1>
            <div class="flex flex-col lg:flex-row  justify-center  w-full max-w-screen-xl mx-auto poppins mt-6 ">
                <div @click="tipoOperacion('Venta')" class=" text-center bg-white mx-4   hover:bg-gray-200  hover:text-gray-600 font-bold text-xl px-2 py-3 lg:mr-2 text-gray-600   cursor-pointer rounded">
                    Quiero comprar
                </div>

                <div @click="tipoOperacion('Alquiler')" class=" text-center bg-white mt-2 lg:mt-0 mx-4 hover:bg-gray-200  hover:text-gray-600 font-bold text-xl px-2 py-3 lg:mr-2 text-gray-600   cursor-pointer rounded">
                    Quiero alquilar
                </div>
                <router-link to="/vender">
                    <div class=" bg-white text-center  mx-4  mt-2  lg:mt-0 hover:bg-gray-200  hover:text-gray-600 font-bold text-xl px-2 py-3 lg:mr-2 text-gray-600   cursor-pointer rounded">
                        Quiero vender
                    </div>
                </router-link>
            </div>

        </div>

        <footer class="absolute bottom-0    w-full py-3" :style="{ backgroundImage: 'url(' + require('@/assets/bg-footer-red.jpg') + ')' }">
            <div class="max-w-screen-xl text-white mx-auto poppins text-sm">
                <div class="flex justify-center items-center">
                    <div class=" text-center  text-xs lg:text-base">
                        Jose Maria Vivas 2022 Copyright © Todos los derechos reservados
                    </div>

                </div>

            </div>

        </footer>

    </section>

</div>
</template>

<script>
import router from "../router"
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'HomeView',
    components: {
        Navbar,
        Footer
    },
    data() {
        return {

        }
    },
    methods: {
        tipoOperacion(payload) {
            router.push({
                name: 'propiedades',
                params: {
                    id: payload
                }
            })
        }
    },
}
</script>

<style>

</style>
