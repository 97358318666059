import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db } from '../firebase'
import router from "../router";
 
 

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    propiedades:[],
    propiedad:{}
  },
  getters: {
  },
  mutations: {
    ...vuexfireMutations,

  },
  actions: {
    obtenerPropiedad: firestoreAction(async ({ bindFirestoreRef }, payload) => {
      return bindFirestoreRef('propiedad', db.collection('Propiedades').doc(payload))
    }),

    obtenerPropiedades: firestoreAction(async({ bindFirestoreRef }) => {
       
      console.log("2")
      return bindFirestoreRef('propiedades', db.collection('Propiedades'))
    }),



    obtenerPropiedadesporOperacion: firestoreAction(({ bindFirestoreRef }, payload) => {
      return bindFirestoreRef('propiedades', db.collection("Propiedades").where("operacion", "==", payload))
    }),
    obtenerPropiedadesporCiudad: firestoreAction(({ bindFirestoreRef }, payload) => {
      return bindFirestoreRef('propiedades', db.collection("Propiedades").where("ciudad", "==", payload))
    }),
  },
  modules: {
  }
})
