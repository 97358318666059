 
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/auth'
  
 

const firebaseConfig = {

  apiKey: "AIzaSyAtfGACQ21EapD3FO2S71TWJFaij31DnVc",
  authDomain: "jmvinmobiliaria-73917.firebaseapp.com",
  projectId: "jmvinmobiliaria-73917",
  storageBucket: "jmvinmobiliaria-73917.appspot.com",
  messagingSenderId: "29679148881",
  appId: "1:29679148881:web:31b4feda439f819fd5bd70"



  };
  // Initialize Firebase
  export const fb = firebase.initializeApp(firebaseConfig);


  export const db = firebase.firestore();






  
